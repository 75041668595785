import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="https://saranouri.ir" className="image avatar"><img src={avatar} alt="" /></a>
                    <h1 className="text-align-desc header-desc"><strong className="name-tag">I am Sara Nouri</strong><br /> a Novice programmer; <br /> 
                    </h1>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
